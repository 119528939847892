import "./HomeInstitutionsStyles.css";
import React, { useState, useEffect } from "react";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { BASE_URL } from "../../env";

const HomeInstitutions = () => {
  const [institutions, setInstitutions] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchAllInstitutions();
  }, []);

  const fetchAllInstitutions = async () => {
    try {
      const response = await fetch(`${BASE_URL}/institution`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (Array.isArray(data) && data.length > 0) {
        setInstitutions(data);
      }
    } catch (error) {
      console.error("Error fetching institutions:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="loading">Loading institutions...</div>;
  }

  return (
    <div className="institutions">
      <div className="institutions-title">
        <h2>INSTITUTIONS</h2>
      </div>

      {institutions.length === 0 ? (
        <p className="no-data">No institutions available at this time.</p>
      ) : (
        institutions.map((institution, index) => (
          <div className="institutions-img" key={index}>
            <img
              src={institution.imageUrl || "fallback-image-url.jpg"}
              alt={`${institution.name} image`}
              className="institution-image"
            />
            <div className="institutions-img-overlay">
              <h5>{institution.name}</h5>
              <p className="text-appear">
                {institution.description
                  ? institution.description.slice(0, 310) + "..."
                  : "No description available."}
              </p>

              <div className="contact-info">
                <FaPhoneAlt
                  size={20}
                  className="institutions-img-overlay-icon"
                />
                <a href={`tel:${institution.phone}`} className="contact-text">
                  {institution.phone || "No phone available"}
                </a>
              </div>

              <div className="contact-info">
                <FaEnvelope
                  size={20}
                  className="institutions-img-overlay-icon"
                />
                <a
                  href={`mailto:${institution.email}`}
                  className="contact-text"
                >
                  {institution.email || "No email available"}
                </a>
              </div>

              {institution.location ? (
                <a
                  href={institution.location}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-info"
                >
                  <MdLocationOn
                    size={20}
                    className="institutions-img-overlay-icon"
                  />
                </a>
              ) : (
                <span className="contact-info">
                  <MdLocationOn
                    size={20}
                    className="institutions-img-overlay-icon"
                  />
                  Location unavailable
                </span>
              )}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default HomeInstitutions;
