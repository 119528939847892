import "./NavStyles.css";
import logo from "../images/KMO Logo.png";
import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <header style={{minHeight:'12dvh'}}>
      <div className="img-co">
        <Link to="/">
          <img
            className="logo"
            src={logo}
            alt="logo"
            style={{
              width: "100px",
              minHeight: "120px",
              objectFit: "contain",
              marginBottom: "5px",
            }}
          />
        </Link>
      </div>
      <input type="checkbox" id="nav-toggle" className="nav-toggle" />
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About us</Link>
          </li>
          <li>
            <Link to="/institutions">Institutions</Link>
          </li>
          <li>
            <Link to="/courses">Courses</Link>
          </li>
          <li>
            <Link to="/contact">Contact us</Link>
          </li>
          <li>
            <Link to="/career">Career</Link>
          </li>
        </ul>
      </nav>
      <label for="nav-toggle" className="nav-toggle-label">
        <span></span>
      </label>
    </header>
  );
};

export default Nav;
