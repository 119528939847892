import "./AdministrationMembersStyles.css";
import image from "../../images/mentor-image.png";
import React from "react";

const AdministrationMembers = () => {
  return (
    <div style={{height:"100px"}}></div>
    // <div className='administration-members'>
    //   <div className='member-container'>
    //     <div className='member-card'>
    //       <img className='member-img' src={image} alt='member image' />
    //       <div className='member-details'>
    //         <p>Mohammed Shajin P</p>
    //         <p>Mentor</p>
    //       </div>
    //     </div>
    //     <div className='member-card'>
    //       <img className='member-img' src={image} alt='member image' />
    //       <div className='member-details'>
    //         <p>Mohammed Shajin P</p>
    //         <p>Mentor</p>
    //       </div>
    //     </div>
    //     <div className='member-card'>
    //       <img className='member-img' src={image} alt='member image' />
    //       <div className='member-details'>
    //         <p>Mohammed Shajin P</p>
    //         <p>Mentor</p>
    //       </div>
    //     </div>
    //     <div className='member-card'>
    //       <img className='member-img' src={image} alt='member image' />
    //       <div className='member-details'>
    //         <p>Mohammed Shajin P</p>
    //         <p>Mentor</p>
    //       </div>
    //     </div>
    //   </div>

    //   <div className='member-container'>
    //     <div className='member-card'>
    //       <img className='member-img' src={image} alt='member image' />
    //       <div className='member-details'>
    //         <p>Mohammed Shajin P</p>
    //         <p>Mentor</p>
    //       </div>
    //     </div>
    //     <div className='member-card'>
    //       <img className='member-img' src={image} alt='member image' />
    //       <div className='member-details'>
    //         <p>Mohammed Shajin P</p>
    //         <p>Mentor</p>
    //       </div>
    //     </div>
    //     <div className='member-card'>
    //       <img className='member-img' src={image} alt='member image' />
    //       <div className='member-details'>
    //         <p>Mohammed Shajin P</p>
    //         <p>Mentor</p>
    //       </div>
    //     </div>
    //     <div className='member-card'>
    //       <img className='member-img' src={image} alt='member image' />
    //       <div className='member-details'>
    //         <p>Mohammed Shajin P</p>
    //         <p>Mentor</p>
    //       </div>
    //     </div>
    //   </div>

    //   <div className='member-container'>
    //     <div className='member-card'>
    //       <img className='member-img' src={image} alt='member image' />
    //       <div className='member-details'>
    //         <p>Mohammed Shajin P</p>
    //         <p>Mentor</p>
    //       </div>
    //     </div>
    //     <div className='member-card'>
    //       <img className='member-img' src={image} alt='member image' />
    //       <div className='member-details'>
    //         <p>Mohammed Shajin P</p>
    //         <p>Mentor</p>
    //       </div>
    //     </div>
    //     <div className='member-card'>
    //       <img className='member-img' src={image} alt='member image' />
    //       <div className='member-details'>
    //         <p>Mohammed Shajin P</p>
    //         <p>Mentor</p>
    //       </div>
    //     </div>
    //     <div className='member-card'>
    //       <img className='member-img' src={image} alt='member image' />
    //       <div className='member-details'>
    //         <p>Mohammed Shajin P</p>
    //         <p>Mentor</p>
    //       </div>
    //     </div>
    //   </div>

    // </div>
  );
};

export default AdministrationMembers;
